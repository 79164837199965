export const hasHourTime = isoString => {
    const s = isoString.split('T')

    if (s.length < 1) {
        return false
    } else {
        return !(s[1].startsWith('00:00:00'))
    }
}

export const timeToDescriptiveTimeOfDay = isoString => {
    const date = new Date(isoString)
    const hour = isoString.endsWith('Z') ? date.getUTCHours() : date.getHours()

    if (hour > 18) {
        return 'evening'
    }

    if (hour > 13) {
        return 'afternoon'
    }

    if (hour > 10) {
        return 'noon'
    }

    if (hour > 8) {
        return 'morning'
    }

    if (hour > 6) {
        return 'super early'
    }

    if (hour > 2) {
        return 'super late'
    }

    return 'midnight'
}

export const timeToDayOfWeek = isoString => {
    const date = new Date(isoString)

    switch (date.getDay()) {
        case 0:
            return 'sunday'
        case 1:
            return 'monday'
        case 2:
            return 'tuesday'
        case 3:
            return 'wednesday'
        case 4:
            return 'thursday'
        case 5:
            return 'friday'
        case 6:
            return 'saturday'
        default:
            return ''
    }
}

export const timeToDescriptive = isoString => {
    return `${timeToDayOfWeek(isoString)} ${timeToDescriptiveTimeOfDay(isoString)}`
}
