import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { timeToDescriptive } from '../utils/time'
import { capitalizeAWord } from '../utils/words'
import { MDXRenderer } from "gatsby-plugin-mdx"

const BlogPostTemplate = ({data, pageContext, location}) => {
    const { mdx: post, site: { siteMetadata: {title}} } = data
    const { previous, next } = pageContext

    return (
        <Layout location={location} title={title}>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
            />
            <article>
                <header>
                    <h1
                        style={{
                            marginTop: rhythm(1),
                            marginBottom: 0,
                            paddingBottom: rhythm(1/2),
                        }}
                    >
                        {post.frontmatter.title}
                    </h1>
                    <p
                        style={{
                            ...scale(-1 / 5),
                            display: `block`,
                            marginBottom: rhythm(1),
                            marginTop: rhythm(1/4)
                        }}
                    >
                        {post.frontmatter.datePretty} - {capitalizeAWord(timeToDescriptive(post.frontmatter.date))}
                    </p>
                </header>
                <MDXRenderer>{post.body}</MDXRenderer>
                <hr
                    style={{
                        marginBottom: rhythm(1),
                    }}
                />
                <footer />
            </article>

            <nav>
                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>
        </Layout>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
      site {
          siteMetadata {
              title
          }
      }
      mdx(fields: {slug: {eq: $slug }}) {
          excerpt(pruneLength: 160)
          body
          frontmatter {
              title
              datePretty: date(formatString: "MMMM DD, YYYY")
              date
              description
          }
      }
  }
`
